import VideoCard from '../components/videoCard/VideoCard';

function Home() {
	return (
		<div>
			<div className="pageBanner">
				<img className="bannerImg" src="/assets/img/banners/default.png" alt="" height="400" width="1600" />
				<h2 className="bannerWatermark">Eldon Fox, Artist and Artisan</h2>
			</div>
			<div className="triptych">
				<section>
					<h3>Visit The Online Gallery!</h3>
					<p>Find something beautiful for your home with options starting at under $20 dollars. Purchase an original artwork and I'll deliver it, if you live in the Eugene/Springield area. Possibly for free! :)</p>
					<p><a href="https://shop.eldonfox.com/" target="_blank" rel="noreferrer" className="button">Visit the Gallery</a></p>
				</section>
				<section>
					<VideoCard props={{srcPath: 'assets/json/home.json', timeout: 4, localStore: 'home'}}></VideoCard>
				</section>

				{/* <section>
					<h3>Painting Highlight</h3>
				</section> */}
			</div>
		</div>
	)
}

export default Home;
