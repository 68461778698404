import React, { useState } from 'react';

import Alerts from "./Alerts";

function Notifications() {
	const [msgs, setMsgs] = useState([]);
	const [msgTimeStamp, setMsgTimestamp] = useState('');

	const enableNotifications = () => {};

	const notificationTest = () => {
		Notification.requestPermission()
		.then(perm => {
			alert(perm);
		})
	}

	const createNotification = () => {
		Notification.requestPermission()
		.then(perm => {
			if(perm === "granted") {
				new Notification("Example Notification", {body: "Notification body text", icon: 'assets/img/app.svg'});
			}
		})
		.catch(error => {
			console.error("error", error);
		})
	}

	const fetchNotification = () => {
		fetch('/api/v1/msgs?' + Date.now())
		.then(res => {
            if(!res.ok) {
                console.error("Error" + res.status + ": " + res.statusText);
            }

            return res.json();
        })
		.then(data => {
			setMsgs(data.data.msgs);
			setMsgTimestamp(data.timeStamp);
		});
	}
/*
	const startLongPolling = () => {
		let intervalId;

		function longPoll() {
			fetch('https://localhost:8443/api/v1/msgs', { signal: new AbortController().signal })
			.then(response => {
				console.log('Received new message:', response);
				// Process the response here

				// Uncomment the line below to restart polling immediately after receiving data
				// longPoll();

				// Comment out the line above and use setInterval below to restart polling after a delay
			})
			.catch(error => {
				console.error('Long polling failed:', error);
				// Handle errors or connection aborts

				// Uncomment the line below to retry immediately after an error
				// setTimeout(longPoll, 2000); // Retry after 2 seconds

				// Comment out the line above and use setInterval below to retry after a delay
			});
		}

		// Uncomment the line below to start long polling immediately
		// longPoll();

		// Comment out the line above and use setInterval below to start after a delay
		intervalId = setInterval(longPoll, 3000); // Retry every 3 seconds (3000 milliseconds)

		// Uncomment the line below to stop long polling after a certain time
		setTimeout(() => clearInterval(intervalId), 10000); // Stop after 10 seconds
	}
*/

	return (
		<section>
			<Alerts />

			<div>
				<button onClick={enableNotifications}>Enable Push Notifications</button>

				<button
					onClick={notificationTest}>Notification Test</button>

				<button
					onClick={createNotification}>Create Notification</button>

				<h3>Polling Test</h3>

				<button
					onClick={fetchNotification}>Fetch Notification</button>

				<ol>
					{msgs.map((msg, msgIndex) => {
						return <li key={msgIndex}>{msg.title}</li>
					})}
				</ol>
				<cite>Last updated: {msgTimeStamp}</cite>
			</div>
		</section>
	);
}

export default Notifications;
