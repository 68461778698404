import { useState } from "react";

function Contacts() {
	const [emailInputs, setEmailInputs] = useState({});

	const handleChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setEmailInputs(values => ({...values, [name]: value}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(emailInputs);
	};

	return (
		<section>
			<h3>Send Me a Message</h3>

			<form method="post" onSubmit={handleSubmit}>
				<div className="formset text">
					<label htmlFor="emailFromName">Your Name:</label>
					<input type="text" className="text" id="emailFromName" name="emailFromName" onChange={handleChange} />
				</div>
				<div className="formset text">
					<label htmlFor="emailFrom">Your Email:</label>
					<input type="text" className="text" id="emailFrom" name="emailFrom" onChange={handleChange} />
				</div>
				<div className="formset text">
					<label htmlFor="emailSubject">Subject:</label>
					<input type="text" className="text" id="emailSubject" name="emailSubject" onChange={handleChange} />
				</div>
				<div className="formset textarea">
					<label htmlFor="emailBody">Message:</label>
					<textarea id="emailBody" name="emailBody" rows="5" cols="25" onChange={handleChange}></textarea>
				</div>
				<div className="actionBar">
					<input type="submit" value="Send Email" />
				</div>
			</form>
		</section>
	);
}

export default Contacts;
