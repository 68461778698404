import useFetch from '../useFetch';
import LoadingBlock from '../LoadingBlock';

import './VideoCard.css';

function VideoCard({props}) {
	const { data:home, isLoading, error } = useFetch(props.srcPath, props.timeout, props.localStore);

	return (
		<div className='videoCard'>
			<LoadingBlock className='cardLoading' isLoading={isLoading} error={error} />

			{home && home.latestVideo &&
				(<div className='card'>
					<h3 className='cardTitle'>Video Spotlight - "{home.latestVideo.title}"</h3>
					<div className='cardBody'>
						<a className='cardImage' href={home.latestVideo.link} target="_blank" rel="noreferrer">
							<img src={home.latestVideo.image} height="200" width="356" alt="YouTube video." />
						</a>
						<p className='cardDescription'>{home.latestVideo.description}</p>
					</div>
					<p className='cardAction'><a href={home.latestVideo.link} target="_blank" rel="noreferrer" className="button">Watch The Video</a></p>
				</div>)
			}
		</div>
	);
}

export default VideoCard;
