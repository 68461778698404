const LoadingBlock = ({ isLoading, error }) => {
	return (
		<div>
			{ isLoading && <div className="loading"> Loading ... </div> }
			{ error && <div className="loading"> { error } </div> }
		</div>
	);
}

export default LoadingBlock;
