import { Link } from "react-router-dom";

import './NavList.css';

const EFMenu = ({props}) => {
	let navStyleClasses = "navList" + (props.className ? ' ' + props.className  : '');
	let listClassName = 'listItems' + (props.listClassName ? ' ' + props.listClassName  : '');
	let TitleTag = props.titleTag ?? 'h2';

	if(typeof props.navAriaLabel === 'undefined') {
		throw new Error('Nav tag Aria label (navAriaLabel) is required.');
	}

	/**
	 * Creates the markup for the nav item link.
	 * @param {*} anchor link properties
	 * @returns an anchor tag or string if a link is not assigned.
	 */
	const configLink = (anchor) => {
		let targetVal = anchor.target || '_self';
		//let rel = target === '_self' ? '' : 'noreferrer';

		let attributes = {
			to: anchor.link,
			target: targetVal,
			...(targetVal !== '_self' ? {rel: 'noreferrer'} : null),
			...(anchor.className ? {className: anchor.className} : null)};

		return (
			anchor.link != null ?
				<Link className="listLink" {...attributes}>{anchor.text}</Link> :
				anchor.text
		);
	};

	return (
		<nav className={navStyleClasses} aria-label={props.navAriaLabel}>
			<TitleTag className="title">{props.listTitle ?? 'Navigation Menu'}</TitleTag>

			<ul className={listClassName}>
				{props.navItems.map((val, key) => {
					return (
						<li className={val.link ? 'listItem' : 'listItem nolink'}
								key={'siteNavItem' + key}>
							{configLink(val)}
						</li>
					);
				})}
			</ul>
		</nav>
	);
}

export default EFMenu;
