import { Outlet } from "react-router-dom";
import { useRef } from "react";

import Header from './Header';
import Footer from './Footer';

import './assets/css/App.css';

function Layout() {
	const pageWrapper = useRef(null);

	return (
		<div className='siteWrapper'>
			<Header props={{pageContent: pageWrapper}} />
			<section className="pageWrapper" id="pageWrapper" ref={pageWrapper}>
				<Outlet/>
			</section>
			<Footer props={{pageContent: pageWrapper}} />
		</div>
	)
}

export default Layout;
