import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import EFMenu from './components/menu/EFMenu';

const Header = ({props}) => {
	/**
	 * Make the primary portion of the site header sticky when scrolling out of view.
	 */
	const siteHeader = useRef(null);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			const $pageContent = props.pageContent.current;
			const $siteHeader = siteHeader.current;

			if($siteHeader.offsetTop < window.scrollY) {
				$pageContent.style.marginTop = $siteHeader.offsetHeight + 'px';
				$siteHeader.classList.add('sticky');
			} else {
				$siteHeader.classList.remove('sticky');
				$pageContent.style.marginTop = 'auto';
			}
		}, { passive: true });
	});

	// Site nav items
	let menuItems = [
		{text: 'Home', link: '/'},
		{text: 'About', link: '/about'},
		{text: 'Connect', link: '/connect'},
		{text: 'Gallery', link: 'https://shop.eldonfox.com/', target: '_blank'},
	];

	return (
		<header className="globalHeader">
			{/* <div className="headerSecondary">
				Options
			</div> */}

			<div className="headerPrimary" id="siteHeader" ref={siteHeader}>
				<Link id="appLogo" to="/">
					<img src="/assets/img/logo.svg" alt="Eldon Fox LLC" height="48" width="194" />
					<h1 className='siteTitle'>
						Eldon Fox
					</h1>
				</Link>
				<EFMenu props={{titleTag: 'h2', navItems: menuItems,
					menuTitle: 'Site Navigation'}} />
			</div>
		</header>
	);
}

export default Header;
