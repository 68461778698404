import { useEffect, useRef } from "react";
import NavList from "./components/navList/NavList.js";
import Social from "./components/social/Social";

function Footer({props}) {
	/**
	 * Adjust bottom of page content to account for global footer height.
	 */
	const siteFooter = useRef(null);
	let currentYear = new Date().getFullYear();

	useEffect(() => {
		const applyFooterPadding = () => {
			let $footer = siteFooter.current;
			const $pageContent = props.pageContent.current;

			$pageContent.style.paddingBottom = $footer.offsetHeight + 'px';
		};

		window.addEventListener('resize', applyFooterPadding);

		applyFooterPadding();
	});

	let footerNavItmes = [
		{text: 'Press', link: '/resources'},
		{text: 'Site Map', link: '/sitemap'},
	];

	return (
		<footer className="siteFooter" ref={siteFooter}>
			<Social />
			<NavList props={{
					navAriaLabel: 'Footer',
					navItems: footerNavItmes,
					listTitle: 'Other Resources'}} />
			<cite className="cite" title="© 1998-2024 by Eldon Fox LLC, All Rights Reserved">© 1998-{currentYear} by Eldon Fox LLC <em>All Rights Reserved ({process.env.REACT_APP_DOMAIN})</em></cite>
		</footer>
	)
}

export default Footer;
