//import { useParams } from "react-router-dom";

function About() {
	// let params = useParams(); // For future use

	return (
		<div>
			<h2>About</h2>
			<p>Download Eldon's <a href="/resources/Eldon_Fox_Artists_Statement.pdf" target="new">Arist's Statement (PDF)</a>.</p>
			<p>
				I grew up in Sunnyvale California, the heart of the "Silicon Valley", once the "Valley of Heart's Delight" a place where fields and orchards gave way to tech companies and high density housing. In my early working life I assembled and tested rugged computer enclosures. I also did some data entry jobs at local companies such as Apple and Cisco. With more of an interest in painting than cubicals, I went North to be close to family and nature. Of course, as soon as I left the beating heart of technological advancement I became interested in computers..
			</p>
			<p>
				It started with a desire to take advantage of this odd network of computers called the internet to show off my paintings. I started taking classes in multi-media and web design at the local community college and then a course on JavaScript. I was introduced to the BASIC programming language when I was young but I lost interest, believing I could perform the same tasks faster by hand. JavaScript was different, it had limitations, but I was able to see its potentials and really started to enjoy the development process.
			</p>
			<p>
				I soon began taking classes in C++ and Visual Basic, becoming increasingly enthralled with computer logic. I truly enjoyed playing with computer memory, creating console apps and exploring advanced data types. I even began creating a DBMS in C. I suddenly found I had earned an AS, with honors, in computer information sciences and the CIS department named me their programming student of the year! Not bad for a painter.
			</p>
			<p>
				I now live in Eugene Oregon with my wife Sarah, a programmer, enjoying a good life with family and friends.
			</p>

			<h3>Fine Arts</h3>
			<p>
				From an early age, art has been a part of my life. The world around me, shapes, color, the play of light and shadow has been a source of wonder and awe. Painting, for me, is a way to not only capture but be a part of the miracle of life. Art is creation. I've never been drawn to one style of art over another. I tend towards realism and surrealism but have also enjoyed the abstract and impressionistic.
			</p>
			<h3>Programming</h3>
			<p>
				Most find it strange that a person who excels in the fine arts would be attracted to programming. I actually have found that the two are quite similar. Both are an exploration of life and the world around us but whereas art is a recreation of matter, programming is a recreation of method. When examining business process you often begin by breaking it down into individual parts. From those parts and their interaction you build your programs into a collective with the goal of emulating (with improvements) the observed process. Programming is the recreation and interaction with the activity of life.
			</p>
			<h3>User Interface and Web Design</h3>
			<p>
				So, with a background in visual arts and computer programming,  UI design is a logical progression. I have not left painting or programming behind. UI is not a melding of the two but rather an extension. Its important that programs not only function properly but that they have an interface to the user that makes their tasks easier. It is important to me that as many people as is possible can access information regardless of physical limitations. Yes, cool whiz-bang affects are nice but can the interface regress smoothly to allow for functional interaction? Reaching that goal is the part of UI that brings me the greatest satisfaction.
			</p>
		</div>
	);
};

export default About;
