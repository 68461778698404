import Notifications from './Notifications';
import NewsLetter from './NewsLetter';
import Contact from './Contact';

function Connect() {
	return (
		<div>
			<h2>Let's Chat!</h2>
			<div className='triptych'>
				<Notifications />
				<NewsLetter />
				<Contact />
			</div>
		</div>
	);
}

export default Connect;
