function Alerts() {
	return (
		<section>
			<h3>Stay Connected!</h3>
			<p>Install the app and be the first to see my latest paintings, get behind-the-scene updates and special discounts!</p>
		</section>
	);
}

export default Alerts;
