import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from './Layout';
import Home from './pages/Home';
import About from './pages/About';
import Resources from "./pages/Resources";
import Connect from "./pages/Connect";
import Contact from "./pages/Contact";
import Notifications from "./pages/Notifications";
import SiteMap from './pages/SiteMap';
import QR from "./pages/QR";
import Error404 from "./pages/Error404";

// import { WebSocketDemo } from "./pages/WebSocketDemo";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="about/:what?" element={<About />} />
                    <Route path="resources" element={<Resources />} />
                    <Route path="connect" element={<Connect />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route path="sitemap" element={<SiteMap />} />
                    <Route path="QR" element={<QR />} />
                    {/* <Route path="webSocketDemo" element={<WebSocketDemo />} /> */}
                    <Route path="*" element={<Error404 />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
