import { Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";

import './EFMenu.css';

const EFMenu = ({props}) => {
	const [navMenuOpen, setNavMenuOpen] = useState(false);
	const navMenu = useRef(null);
	const toggleButton = useRef(null);
	const TitleTag = props.titleTag ?? 'h2';
	const menuClassName = 'menuItems' + (props.menuClassName ? ' ' + props.menuClassName  : '');
	const imploadMobile = props.imploadMobile ?? true; // Hide items on mobile devices?

	const toggleNavMenu = (e) => {
		setNavMenuOpen(!navMenuOpen);
	};

	let navStyleClasses = "eldonfox efmenu" + (imploadMobile ? " imploadMobile" : "") + (props.className ? ' ' + props.className  : '');

	useEffect(() => {
		const closeNavMenu = (e) => {
			if(navMenu.current != null
					&& (navMenuOpen
					&& !navMenu.current.contains(e.target)
					&& !toggleButton.current.contains(e.target))) {
				setNavMenuOpen(false);
			}
		}
		document.addEventListener('mousedown', closeNavMenu);

		return () => {
			document.removeEventListener('mousedown', closeNavMenu);
		};

	}, [navMenuOpen]);

	/**
	 *
	 * @param {*} val link properties
	 * @returns an anchor tag or string if a link is not assigned.
	 */
	const configLink = (anchor) => {
		let targetVal = anchor.target || '_self';
		//let rel = target === '_self' ? '' : 'noreferrer';

		let attributes = {
			to: anchor.link,
			target: targetVal,
			...(targetVal !== '_self' ? {rel: 'noreferrer'} : null),
			...(anchor.className ? {className: anchor.className} : null)};

		return (
			anchor.link != null ?
				<Link onClick={toggleNavMenu} {...attributes}>{anchor.text}</Link> :
				anchor.text
		);
	};

	return (
		<nav className={navStyleClasses}>
			<TitleTag className="title">{props.menuTitle ?? 'Navigation Menu'}</TitleTag>
			{imploadMobile ?
			<button className="menuToggle"
					id="siteMenuButton"
					ref={toggleButton}
					onClick={toggleNavMenu}
					aria-haspopup="true"
					aria-controls="siteMenu">
				<img src="/assets/img/icons/menu.svg" height="24" width="24" alt="Show Site Menu" />
			</button>
			: null }
			<ul className={menuClassName}
					id="siteMenu"
					ref={navMenu}
					role="menu"
					aria-labelledby="siteMenuButton"
					style={{display: navMenuOpen ? 'block' : ''}}>
				{props.navItems.map((val, key) => {
					return (
						<li className={val.link ? 'menuItem' : 'menuItem nolink'}
								role="presentation"
								key={'siteNavItem' + key}>
							{configLink(val)}
						</li>
					);
				})}
			</ul>
		</nav>
	);
}

export default EFMenu;
