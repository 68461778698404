import NavList from "../navList/NavList";
import './Social.css';

function Social() {
	const socialLinks = [
		{text: "Facebook", className: "facebook", ID: "link_facebook", showOnFooter: true, title: "Facebook", alt: "Go to Facebook.", link: "https://www.facebook.com/EldonFoxFineArts/", target: "_blank"},
		{text: "LinkedIn", className: "linkedIn", ID: "link_linkedIn", showOnFooter: true, title: "LinkedIn", alt: "Go to Linked In.", link: "http://www.linkedin.com/in/eldonfox", target: "_blank"},
		{text: "Twitter", className: "twitter", ID: "link_twitter", showOnFooter: true, title: "Twitter", alt: "Go to Twitter.", link: "http://twitter.com/eldonfox", target: "_blank"},
		{text: "YouTube", className: "youTube", ID: "link_youtube", showOnFooter: true, title: "YouTube", alt: "Go to YouTube.", link: "http://www.eldonfox.com/youtube/", target: "_blank"},
		{text: "Instagram", className: "instagram", ID: "link_instagram", showOnFooter: true, title: "Instagram", alt: "Go to Instagram.", link: "https://www.instagram.com/eldonfoxfinearts/", target: "_blank"},
		{text: "Patreon", className: "patreon", ID: "link_patreon", showOnFooter: true, title: "Patreon", alt: "Go to Patreon.", link: "https://www.patreon.com/eldonfox/", target: "_blank"},
	];


	return (
		<section>
			<NavList props={{
				className: 'socialMenu',
				navAriaLabel: 'Social',
				listClassName: 'socialLinks',
				navItems: socialLinks,
				listTitle: 'Find Us on Social Media',}} />
		</section>
	);
}

export default Social;
