function Resources() {
	return (
		<div>
			<h2>Press Resources</h2>
			<ul>
				<li><a href="/resources/Eldon_Fox_Artists_Statement.pdf" target="new">Arist's Statement (PDF)</a></li>
				<li><a href="/resources/Headshot.jpg" target="new">Headshot (JPG)</a></li>
			</ul>
		</div>
	);
}

export default Resources;