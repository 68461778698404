function SiteMap() {
	return (
		<div>
			<h2>Site Map</h2>
			<ul>
				<li>
					<h3>Root Site Layout:</h3>
					<ul>
						<li><h4>Homepage</h4></li>
						<li><h4>About</h4></li>
						{/* <li>
							<h4>Contact</h4>
							<ul>
								<li>Notifications</li>
								<li>Contact Form</li>
								<li>Newsletter/Signup</li>
							</ul>
						</li> */}
						<li>
							<h4>Press Resources</h4>
						</li>
					</ul>
				</li>
				{/* <li>
					<h3>Public Subdomains:</h3>
					<ul>
						<li><h4>Gallery (Shop)</h4></li>
						<li><h4>Painting History</h4></li>
						<li><h4>Art</h4></li>
						<li><h4>Software/Apps</h4></li>
						<li><h4>Flying</h4></li>
					</ul>
				</li> */}
			</ul>
		</div>
	)
}

export default SiteMap;
