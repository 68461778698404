function Error404() {
	return (
		<div>
			<h2>Page Not Found</h2>
			<p>404</p>
		</div>
	);
}

export default Error404;
